/* custome font */
@font-face {
  font-family: MochiyPopOne-Regular;
  src: url("./Assets/Fonts/MochiyPopOne-Regular.ttf");
}
@font-face {
  font-family: NotoSansJP-700;
  src: url("./Assets/Fonts/NotoSansJP-Bold.otf");
}
@font-face {
  font-family: NotoSansJP-300;
  src: url("./Assets/Fonts/NotoSansJP-Light.otf");
}
@font-face {
  font-family: NotoSansJP-500;
  src: url("./Assets/Fonts/NotoSansJP-Medium.otf");
}
@font-face {
  font-family: NotoSansJP-400;
  src: url("./Assets/Fonts/NotoSansJP-Regular.otf");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}
.App {
  text-align: center;
  font-family: NotoSansJP-400;
  font-size: 1.4rem;
  padding-bottom: 2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
[contenteditable] {
  outline: 0px solid transparent;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* mui css override */
.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Custom scroll bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* custom mui text */
.MuiTypography-root {
  font-size: 1.4rem !important;
}
/* calendar mui */
.MuiPickersCalendarHeader-dayLabel {
  font-size: 1.4rem !important;
}
.MuiPickersSlideTransition-transitionContainer {
  font-size: 1.4rem !important;
}
.rbc-off-range-bg {
  background: transparent;
}
.rbc-selected-cell {
  background: #d4d4d4;
}
.MuiMenuItem-root {
  font-size: 1.4rem !important;
  font-family: "NotoSansJP-500";
}

/* custom style react-date-picker */
.rdrDateDisplayWrapper{
  display: none;
}
.MuiMenu-list{
  display: flex;
  flex-direction: column;
}
/* Modal */
.MuiBackdrop-root{
  background-color: rgb(136 136 136 / 50%) !important;
}
/* message not found */
.messageNotFound{
  font-size: 2rem;
}
.rdrDayPassive  
  .rdrDayNumber{
    display: none !important;

  }

  .MuiModal-root > .MuiPopover-paper {
    max-height: 40rem !important;
  }